import classnames from "classnames";
import React, { FC } from "react";
import styles from "./style.module.scss";

interface LoaderProps {
  spinning?: boolean;
  fullScreen?: boolean;
}

const Loader: FC<LoaderProps> = ({ spinning = true, fullScreen = false }) => (
  <div
    className={classnames(styles.loader, {
      [styles.hidden]: !spinning,
      [styles.fullScreen]: fullScreen,
    })}
  />
);

export default Loader;
