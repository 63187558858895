import notification from "antd/lib/notification";
import { navigate } from "gatsby";
import React, { FC, useEffect } from "react";
import { useIsAuthenticated, useSession } from "apollo/local";
import CommonLayout from "components/layouts/common";
import Loader from "components/loader";
import routes from "routes";

const Home: FC<{ test: boolean }> = () => {
  const {
    isAuthenticated,
    loaded: isAuthenticatedLoaded,
  } = useIsAuthenticated();

  const { mainMenuItems, loaded: sessionLoaded } = useSession();

  useEffect(() => {
    if (isAuthenticatedLoaded && sessionLoaded) {
      if (!isAuthenticated) {
        navigate(routes.login);
      } else if (mainMenuItems.length > 0) {
        navigate(mainMenuItems[0].url);
      } else {
        notification.error({
          message: "Ошибка!",
          description: "У пользователя нет доступа ни для одной старницы",
        });

        navigate(routes.login);
      }
    }
  }, [sessionLoaded, isAuthenticated, mainMenuItems, isAuthenticatedLoaded]);

  return (
    <CommonLayout>
      <Loader />
    </CommonLayout>
  );
};

export default Home;
